.main {
  margin: 25px auto;
  max-width: 1024px;
}

.app-header {
  text-align: center;
}

.app-header img {
  width: 65%;
  max-width: 300px;
}

.app-content {
  padding: 50px 30px;
  min-height: 400px;
}

.app-footer {
  text-align: center;
  font-size: 10pt;
}

.start-game-control {
  margin-top: 20px;
  text-align: right;
}

.start-game-teams {
  margin-top: 30px;
}

.join-game .join-control {
  margin-top: 40px;
}

.join-game .join-control .btn {
  background-color: #000;
  border: 0;
  border-radius: 0;
  padding: 10px 20px;
}

.game-board {
  .game-title {
    text-align: center;

    span {
      background-color: #E7328B;
      text-transform: uppercase;
      color: #fff;
      font-weight: bold;
      padding: 5px 15px;
      font-size: 15pt;
    }
  }

  .game-table {
    margin-top: 30px;
    text-align: center;

    .question {
      margin-bottom: 40px;
    }

    .answers {
      header span {
        border-bottom: 5px solid #E7328B;
        text-transform: uppercase;
        color: #000;
        font-weight: bold;
        padding: 5px 15px;
      }

      .reservations {
        margin: 0 auto;
        margin-top: 20px;
        max-width: 400px;
      }
    }
  }
}

.admin-board {
  .row {
    margin-bottom: 20px;
  }

  .board-header {
    margin-bottom: 20px;
    font-size: 14pt;

    > span {
      padding: 5px;
      border-bottom: 3px solid #E7328B;
    }
  }
  margin-bottom: 20px;;

  .board-cell {
    border: 1px solid #ddd;
    background-color: #fbfbfb;
    padding: 15px 20px;
    border-radius: 5px;
    height: 100%;

    header {
      font-size: 13pt;
      font-weight: bold;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }

  .question {
    > span {
      margin-bottom: 20px;
      display: block;
    }

    .btn {
      font-size: 11pt;
      width: 160px;
      margin-right: 10px
    }
  }

  .manage-game {
    .btn {
      font-size: 11pt;
      width: 180px;
      margin-bottom: 10px;
    }
  }
}
